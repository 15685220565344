// import './elisabeta.css'
import Footer from "../../../components/footer/footer";

import img1 from './img1.jpg';
import img2 from './img2.jpg';
import img3 from './img3.jpg';
export default function Elisabeta(){
    return (
        <>
            <div>
                <h1> M.S. Regina Elisabeta și portul popular</h1>
                <div className="postInfo">
                    <span className="author">Asociația Poarta Făgărașului</span>
                    <span className="date">20 August 2020</span>
                </div>
                <div className="body">
                    <p>”M.S. Regina Elisaveta a României pórtă cu fală costumul național român și pentru a încuraja și mai mult artele casnice la sate, Majestatea Sa a făcut ca tóte Dómnele din elita socială să pórte cu plăcere costumul național.” – Modele de cusături pentru costume naționale române culese din județul Muscel, Ioan Niculescu, 1893</p>

                    <img src = {img1} alt =""/>

                    <p className="sursa">Sursă fotografie – Modele de cusături pentru costume naționale române culese din județul Muscel, Ioan Niculescu, 1893</p>

                    <p>
                    M.S. Regina Elisabeta a iubit portul popular românesc și a fost o adevărată ambasadoare a acestuia. Acțiunile de promovare au început prin a convinge doamnele din înalta societate să poarte costumul tradițional. A susținut astfel eliminarea barierei de gândire potrivit căreia portul tradițional este purtat doar de țărani și nu se cuvine a îi oferi un loc de cinste în garderobele sofisticate ale nobilimii. Regina Elisabeta a dărâmat cu tărie și convingere aceste prejudecăți și prin exemplul personal a încurajat purtarea hainelor tradiționale atât în viața de zi cu zi, cât și la adunări importante și evenimente mondene.
                    </p>
                    <p>
                    În introducerea cărții ”Arta de a lucra cu suveica” scrisă de prietena sa, Lady Katherin Hoare, apărută în 1910 la Londra, însăși regina subliniază că portul popular este la fel de frumos purtat de o femeie simplă, de la țară care își îndeplinește responsabilitățile cu trudă, cât și de o doamnă a societății mondene care încă păstrează onorabilul meșteșug al războiului de țesut, mărturisind: ”Oare există tablou mai încântător decât o țărancă româncă, îmbrăcată în costum popular, cu fusta roșie sau oranj, cu broboada galbenă aruncată peste cozile negre, cu ochi mari, negri și luminoși, cu ulciorul verde pe cap, grăbindu-se spre casă, sau o doamnă româncă, cu veșminte splendide, cu un minunat val alb sau galben, lucrând la războiul de țesut?”.
                    </p>

                    <img src={img2} alt=""/>
                    <p className="sursa">Sursă fotografie –  Franz Ducheck, București</p>
                    <p>O recunoaștem așadar pe regina Elisabeta prin dantelele pe care le-a lucrat de-a lungul timpului, prin dedicarea pentru această artă și prin atașamentul puternic față de tot ce înseamnă cultură și tradiții românești. O revedem de asemenea surprinsă în multe ipostaze purtând mândră costumul popular, influențând astfel o întreagă societate.</p>

                    <img src={img3} alt = ""/>
                    <p className="sursa">1900 – Ilustrata de epocă înfățișând-o pe Regina Elisabeta, în port popular romanesc</p>
                    <p>Această tendință de a reînvia portul românesc, începută de M.S. Regina Elisabeta, o poate continua fiecare dintre noi, prin exemplul personal, prin cunoașterea și valorificarea pieselor de vestimentație autentice și prin împărtășirea informațiilor cu prietenii, cu alți oameni, așa cum și regina a reușit cu ceva timp în urmă.</p>

                </div>
            </div>
            <Footer/>
        </>
    )
}