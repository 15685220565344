import Header from "../../components/header/header"
import Posts from "../posts/posts"
import Sidebar from "../../components/sidebar/sidebar"
import Footer from "../../components/footer/footer"
import "./activitati.css"

export default function Activitati(){
    return (
        <>
        <Header/>
        <div className="activitati">
            <Posts/>
            <Sidebar/>
        </div>
        <Footer/>
        </>
    )
}