import "./sidebar.css"

export default function Sidebar(){
    return (
        <div className="sidebar">
            <div className="sidebarItem">
                <span className="sidebarTitle">Despre noi</span>
                <img src = "" alt = ""/>
                <p>
                    Suntem o organizație de tineret cu sediul în Șuici. Scopul nostru este dezvoltarea comunității prin implicarea tinerilor în proiecte locale și internaționale.
                </p>
            </div>

        <div className="sidebarItem">
            <span className="sidebarTitle">Despre proiecte</span>
            <ul className="sidebarList">
                <li className="sidebarListItem">
                    <a tagrget ="_blank"
                    href = "https://startong.ro/blog/lada-de-zestre-a-topologului-asociatia-poarta-fagarasului/">Lada de Zestre - Start ONG</a>
                </li>
            </ul>

            <span className="sidebarTitle">Parteneri in alte proiecte</span>
            <ul className="sidebarList">
                <li className="sidebarListItem">
                    <a target="_blank" href="https://jurnaluldearges.ro/un-proiect-pe-cat-de-frumos-pe-atat-de-inedit-o-echipa-de-arhitecti-pasionati-salveaza-casele-vechi-de-pe-valea-topologului-147845/">
                        Pe Topolog in Sus
                    </a>
                </li>

                <li className="sidebarList">
                    <a target = "_blank" href="https://jurnaluldearges.ro/constructia-autostrazii-pitesti-sibiu-ii-mobilizeaza-pe-specialistii-in-patrimoniu-proiect-pentru-identificarea-si-salvarea-caselor-traditionale-de-pe-valea-topologului-140835/">
                        Pe Topolog in Sus - lansare proiect
                    </a>
                </li>

            </ul>

            <span className="sidebarTitle">Despre Șuici</span>
            <ul className="sidebarList">
                <li className="sidebarListItem">
                    <a target="_blank" href="https://www.cjarges.ro/web/suici/prezentare-comuna">Comuna Suici</a>
                </li>
            </ul>

        </div>

        <div className="sidebarItem">
            <span className="sidebarTitle">Urmărește-ne</span>
            <div className="sidebarSocial">
                <a href = "https://www.facebook.com/Asocia%C8%9Bia-Poarta-F%C4%83g%C4%83ra%C8%99ului-266322627627871/"
            target="_blank">
                <i className="sidebarIcon fab fa-facebook"></i>
                </a>

                <a target="_blank" href="https://www.instagram.com/poartafagarasului/">
                <i className ="sidebarIcon fab fa-instagram"></i>
                </a>
            </div>
        </div>
        </div>
    )
}