import './2.lzt.css';
import img1 from "./img1.png";
import Footer from "../../../components/footer/footer";

export default function Lzt(){
    return (
        <>
        <h1> Lada de Zestre a Topologului</h1>
        <div className="postInfo">
            <span className="author">Asociația Poarta Făgărașului</span>
            <span className="date">2 Iulie 2021</span>
        </div>

        <div className="body">
            <p>
            Când te gândești la costumul popular, tu ce imagine ai în minte? Sau, oare, să te întreb altfel: ai o singură imagine, sau nu te poți hotărî ce să alegi dintre 15-20 de imagini?
            </p>
            <p>
            Dacă ai ales prima variantă, permite-mi să îți spun un secret: când vorbim despre costumul popular românesc, nu ne putem rezuma la un singur ansamblu vestimentar, ci la cel puțin unul pentru fiecare subzonă etnografică. Te-am făcut curios? Ia-ți 5 minute pentru tine și continuă să citești.
            </p>
            <p>
            Dacă ai ales a doua variantă, m-aș bucura să știu că vei citi următoarele informații despre portul popular din subzona etnografică Topolog.
            </p>
            <p>
            De-a lungul timpului s-a creionat o tendință tot mai accentuată de încercare de revenire către portul popular autentic și original, portul popular al bunicilor și străbunicilor noștri. Ca această mișcare de promovare să fie consolidată este necesar să se bazeze pe cunoașterea „zestrei” locale, încă existente în comunități, păstrată din generație în generație. Noi am fost impresionați de frumusețea costumului din zona de nord a Văii Topologului, Argeș și ne-am dorit să împărtășim această bucurie de a descoperi portul popular autentic și cu tine, cel care citești acum. Astfel s-a născut ideea proiectului „Lada de Zestre a Topologului” care este sprijinit cu foarte multă încredere de către echipa programului de finanțare Start ONG. Portul popular specific zonei Topolog se distinge în principal în costumul de femeie. Ia cusută foarte bogat are ca element inedit mâneca răsfrântă care creează o manșetă. Partea inferioară a costumului este compusă din zăvelcă (o fotă îngustă purtată în partea din spate) și catrință (un șorț îngust purtat în partea din față) peste poale, legate cu bete cu humuruzi (mărgele din porțelan, cusute pe margini în grupuri de trei). În ceea ce privește portul bărbătesc, acesta se distinge prin cămașa largă, chimirul de piele, pălăria cu boruri mici sau căciula, cojocul de lână și pantalonii lejeri țesuți din lână sau din bumbac.
            </p>

            <img src={img1} alt=""/>

            <p>
            Entuziasmați de descoperirea acestor piese, ne-am dorit să le prezentăm și comunităților Tigveni, Cepari, Șuici și Sălătrucu, în speranța că în vreo ladă de zestre vom mai găsi astfel de costume. De aceea am gândit proiectul „Lada de Zestre a Topologului” în patru activități principale: informare, atelier de cusut, sesiune de fotografii și sesiune de feedback.
            </p>

            <p>
            Sesiunea de informare este gândită să răspundă întrebărilor participanților, făcând o călătorie de la primele atestări vizuale ale portului popular, la re-actualizarea lui în perioada regalității, urmărind aspecte precum piesele portului, materiale, tehnici de lucru, influențe etnografice din fiecare zonă a României
            </p>
            <p>
            Atelierul practic este să învățăm – learning by doing. Cum să înțelegem importanța portului popular fără a avea habar despre câtă muncă presupune coaserea unor motive chiar simple?
            </p>
            <p>
            Sesiune de fotografii presupune redescoperirea „zestrei” locale, surprinderea ei ca ansamblu dar și în detalii prin fotografiere. În această etapă aplicăm cunoștințele învățate, analizăm modele și tehnici de cusături pe costumele pe care le avem și le purtăm cu mândrie. Descoperim povestea costumului, a celei care l-a confecționat și a celor care l-au purtat de-a lungul timpului. Mai mult decât atât, costumul popular ne poate povesti foarte multe despre perioada în care a fost confecționat, originea și statutul social al celor care l-au purtat, clasificându-l în funcție de materialele și tehnicile folosite.
            </p>
            <p>
            Sesiunea de feedback este mai mult despre noi, toți cei implicați. Punem pe hârtie ce am vrut să învățăm și ce am reușit, tragem linie și analizăm.
            </p>
            <p>
            Fotografiile realizate vor fi organizate într-un album dedicat tuturor care au păstrat cu grija costumele populare moștenite, sau primite din generație în generație, și au dus mai departe poveștile acestora. Dacă te-am făcut curios, urmărește-ne în continuare pe paginile de Facebook și Instagram.
            </p>
            <p>
            Proiectul „Lada de Zestre a Topologului” este realizat în parteneriat cu Școala Gimnazială „Gabriel Marinescu” Tigveni, Școala Gimnazială „Nicolae Velea” Cepari, Școala Gimnazială „Toma Brătianu” Șuici, Școala Gimnazială „Radu Șerban” Sălătrucu, Muzeul Viticulturii și Pomiculturii Golești și Primăria Comunei Șuici.
            </p>
            <p>
            Proiectul ”Lada de Zestre a Topologului” este finanțat prin programul Start ONG, lansat de Kaufland România și implementat de Asociația Act for Tomorrow. Domeniile vizate sunt: educație, sănătate, mediu, social și cultură. Valoarea totală a programului este de 500.000 euro. Programul Start ONG este cel mai simplificat instrument de finanțare comunitară destinate ONG-urilor tinere, instituțiilor non-administrative și grupurilor informale/de inițiativă.
            </p>
            <p>
            #StartONG #ImplicareaFaceDiferenta #KauflandRomania #ActforTomorrow #LadadeZestreaTopologului
            </p>
        </div>
        <Footer/>
        </>
    )
}