import './4.cadou.css';
import img1 from "./cadou-1.png";
import Footer from "../../../components/footer/footer";

export default function Cadou(){
    return (
        <>
        <h1> Cadoul de Craciun </h1>
        <div className="postInfo">
            <span className="author">Asociația Poarta Făgărașului</span>
            <span className="date">23 Decembrie 2022</span>
        </div>

        <div className="body">
            <p>
            Asociația Poarta Făgărașului își încheie activitatea pentru anul 2022 prin proiectul “Cadoul de Crăciun”,  proiect caritabil dedicat copiilor cu vârste cuprinse între 3 și 15 ani din comuna Șuici.
Motivați de dorința de a face o surpriză copiilor și totodată încurajați de oportunitatea oferită de programul Start ONG - faptul că apelul de finanțare din luna decembrie a fost dedicat proiectelor caritabile din domeniul social, am pregătit cadouri de Crăciun pentru 45 de copii din medii dezavantajate. 
            </p>
            <p>
            Fiecare cadou pregătit a conținut atât mult așteptatele dulciuri de Crăciun, articole de îmbrăcăminte călduroase specifice sezonului, dar și cărți și enciclopedii menite să le stârnească curiozitatea pentru lectură și pentru a descoperi lucruri noi. 
Cadourile au fost oferite cu ajutorul voluntarilor asociației în rolul de spiriduși pentru a aduce bucurie atât copiilor implicați, dar și familiilor acestora.
            </p>
            <p>
            Proiectul "Cadoul de Crăciun" a fost finanțat prin programul Start ONG în cadrul apelului din luna decembrie. Start ONG este un program lansat de Kaufland România și implementat de Asociația Act for Tomorrow. Domeniile vizate sunt: educație, sănătate, mediu, social și cultura. Valoarea totala a programului este de 500.000 euro. Programul Start ONG este cel mai simplificat instrument de finanțare comunitară destinat ONG-urilor tinere, instituțiilor publice și grupurilor informale/ de inițiativă.
           </p>
            <img src={img1} alt=""/>
            <p>
            #StartONG #ImplicareaFaceDiferenta #KauflandRomania #ActforTomorrow #LadadeZestreaTopologului
            </p>
        </div>
        <Footer/>
        </>
    )
}