import TopBar from "./components/topbar/topbar";
import Home from "./pages/home/home"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
  
} from "react-router-dom";
import Activitati from "./pages/activitati/activitati";
import DespreNoi from "./pages/despre_noi/despre_noi";
import Elisabeta from "./pages/postari/1.elisabeta/elisabeta";
import Lzt from "./pages/postari/2.lzt/2.lzt";
import LztFinal from "./pages/postari/3.lzt-final/lzt_final";
import Contact from "./pages/contact/contact";
import Cadou from "./pages/postari/4.cadou/4.cadou";

function App() {
  return (
    <Router>
    <TopBar/>
    <Routes>
      <Route exact path="/" element={<Home/>}/>
     
      <Route path="/post/1" element={<Elisabeta/>}/>

      <Route path="/post/2" element={<Lzt/>}/>
      <Route path="/post/3" element={<LztFinal/>}/>
      <Route path="/activitati" element={<Activitati/>}/>

      <Route path="/despre-noi" element={<DespreNoi/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/post/4" element={<Cadou/>}/>
    </Routes>
    
    </Router>
  );
}

export default App;
