import ElisabetaPost from "./effectivePosts/1.elisabetPost"
import LztPost from './effectivePosts/2.lztPost'
import LztFinalPost from './effectivePosts/3.lztFinalPost'
import CadouPost from "./effectivePosts/4.cadouPost";
import "./posts.css";

export default function Posts() {
    return (
        <div className="posts">
            <ElisabetaPost/>
            <LztPost/>
            <LztFinalPost/>
            <CadouPost/>
        </div>
    )
}