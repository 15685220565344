import Footer from "../../components/footer/footer"
import Header from "../../components/header/header"
import Sidebar from "../../components/sidebar/sidebar"
import "./home.css"

export default function Home() {
    return (

        <>
        <Header/>
        <div className="home">
            <div className="body">
                <div className="section">
                    <span className="sectionTitle">
                        <h1>Cine suntem noi?</h1>
                    </span>
            
                <span className="sectionBody">
                    <p>
                    Asociația Poarta Făgărașului are ca scop dezvoltarea zonei nordice a județului Argeș, văile Topolog, Argeș, Vâlsan, cu centrul în Șuici prin activități la nivel local, național și internațional. Comuna Șuici este Poarta spre Făgăraș, o zonă cu potențial și oameni frumoși care merită să fie ascultați și susținuți.
                    </p>
                    <p>
                    Ne propunem ca împreună să dezvoltăm comunitatea locală și să susținem tinerii și inițiativele lor prin acțiuni sustenabile.
                    </p>
                </span>
            </div>

            <h1 className="sectionTitle" >Direcțiile de acțiune</h1>
            <div className="directions">
                
                <span className="direction">
                    <p className="directionTitle">Educație</p>
                    <p className="directionBody">Credem că schimbarea pleacă în primul rând din familie și apoi din școală. De aceea vrem să ajungem mai aproape de tineri și să îi ajutăm să se familiarizeze cu noile educații non-formale despre domeniile financiar, ecologic, social, civic.</p>
                </span>

                <span className="direction">
                    <p className="directionTitle">Comunitate</p>
                    <p className="directionBody">Locul unde se nasc idei și inițiative este comunitatea. Împreună putem ajuta la o creștere sustenabilă cu impact pe termen lung pentru agricultori și antreprenori din mediul rural.</p>
                </span>

                <span className="direction">
                    <p className="directionTitle">Păstrarea tradițiilor</p>
                    <p className="directionBody">Un popor este definit prin tradiții, obiceiuri, port popular, istorie și limbă. Păstrarea acestora asigură continuitatea identității naționale pe care suntem datori să o apărăm. Ne propunem să încurajăm meșteșugurile locale, păstrarea tradițiilor și a portului popular.</p>
                </span>

                <span className="direction">
                    <p className="directionTitle">Ecologie</p>
                    <p className="directionBody">Mediul este prietenul nostru și casa noastră. De aceea este datoria noastră să îl respectăm și să avem grijă că este protejat, curat și mereu în siguranță.</p>
                </span>
            </div>
            
        </div>
        <Sidebar/>
            
        </div>
    <Footer/>
    </>
    )
}