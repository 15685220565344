import './postStyle.css'
import imagine from "../../../pages/postari/3.lzt-final/img1.png";
import { Link } from 'react-router-dom';

export default function LztFinalPost(){
    return(
        <div className="post">
            <img className="postImg" 
            src = {imagine}
            alt=""/>

        <div className="postInfo">
            <div className="postCats">
                <span className="postCat">Proiecte locale</span>
            </div>
            <span className="postTitle">
                <Link className="link" to = "/post/3">Lada de Zestre a Topologului - final de proiect</Link>
                </span>
            <hr/>
            <span className="postDate">9 Iulie 2021</span>
        </div>
        <p className="postDescription">
        Proiectul &bdquo;Lada de Zestre a Topologului&rdquo; se &icirc;ncheie, așadar, mulțumind tuturor celor care au avut &icirc;ncredere &icirc;n noi, echipei Start ONG și partenerilor, voluntarilor și oamenilor care ne-au primit &icirc;n comunitățile lor cu drag și cu lăzile de zestre deschise.
        </p>
        </div>
    )
}