import './contact.css';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer"
import Sidebar from "../../components/sidebar/sidebar";


export default function Contact(){
    return (
        <>
            <Header />
            <div className="body">
                <div className="contactDetails">
                    <p>Email: office@poartafagarasului.ro</p>
                    <p>Adresa: Șuici, 282, județul Argeș, România</p>

                    <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11236.637261720874!2d24.537491793969757!3d45.244571073587544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474d25194af87eff%3A0x7eee5c255fd8dd13!2s%C8%98uici%20117725!5e0!3m2!1sro!2sro!4v1637333823403!5m2!1sro!2sro"
                        //style={{width:600}, {height:450}, {border:0}, {allowfullscreen:""}, {loading:"lazy"}}
                        ></iframe>
                        </p>

                    <div className="socialIcons">
                    <a href="https://www.facebook.com/Asocia%C8%9Bia-Poarta-F%C4%83g%C4%83ra%C8%99ului-266322627627871/" target="_blank">
                    <i className="sidebarIcon fab fa-facebook"></i>
                    </a>

                    <a target="_blank" href="https://www.instagram.com/poartafagarasului/">
                    <i className="sidebarIcon fab fa-instagram"></i>
                    </a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}