import './lzt_final.css'

import Footer from "../../../components/footer/footer";

export default function LztFinal(){
    return (
        <>
        <h1>Lada de Zestre a Topologului - final de proiect</h1>
        <div className="postInfo">
            <span className="author">Asociația Poarta Făgărașului</span>
            <span className="date">9 Iulie 2021</span>
        </div>

        <div className="body">
        <p>          Ți s-a &icirc;nt&acirc;mplat vreodată, urc&acirc;nd pe o potecă de munte, absorbit de frumusețea muntelui din față, să uiți pur și simplu să te uiți &icirc;napoi? Dacă da, cu siguranță &icirc;nțelegi sentimentul unic: o dată ajuns sus, privind &icirc;napoi pe poteca pe care ai parcurs-o.</p>
<p>			 Noi nu am ajuns &icirc;n v&acirc;rf, &icirc;nsă suntem de părere că proiectul &bdquo;Lada de Zestre a Topologului&rdquo; este unul de succes, cu obiective atinse, indicatori realizați și cel mai important, oameni curioși, care au descoperit ce comori etnografice mai există prin lăzile de zestre de pe Valea Topologului.</p>
<p>         După aproape trei luni de muncă &icirc;n echipă, cercetare, activități cu participanții direcți, sesiuni de fotografiere, ateliere de cusut, &icirc;nt&acirc;lniri de proiect și multe altele, am reușit să aducem proiectul &icirc;n stadiul pe care nu știam dacă ni-l doream, &icirc;nsă era inevitabil &ndash; finalul. Cel mai probabil nu ne doream să se termine, &icirc;nsă cu fiecare atelier &icirc;ncheiat, abia &icirc;l așteptam pe următorul. Ajunși aici, ne uităm &icirc;n urmă, și fie că ne vine să credem sau nu, am reușit să organizăm 20 de ateliere cu mai mult de 100 de tineri și adulți din 5 comunități de pe Valea Topologului. A fost o provocare pentru toți, at&acirc;t pentru noi ca echipă să organizăm aceste activități, c&acirc;t și pentru partenerii noști să ne ofere contextul, dar mai ales pentru beneficiarii direcți, pentru cei pe care, sperăm noi, i-am convins că portul popular chiar merită readus &icirc;n atenția noastră, a tuturor. Am &icirc;nvățat că socoteala de acasă (&icirc;n cazul nostru de la sediu), nu se potrivește mereu cu cea din t&acirc;rg (de la activități). Contextul actual ne-a &icirc;ncurcat puțin, ne-a determinat să reg&acirc;ndim calendarul de activități de c&acirc;teva ori chiar, și suntem foarte fericiți, că &icirc;n final nu a fost nevoie de soluția de avarie &ndash; organizarea activităților online.</p>
<p>			Dincolo de provocările organizatorice, din punctul nostru de vedere, activitățile au fost pline de emoții, de ambele părți. Fiecare comunitate ne-a primit &icirc;ntr-un fel unic și special, nu putem clasifica sau ierarhiza grupurile de participanți cu care am lucrat. Ne-am bucurat peste tot de primiri călduroase, cooperare din partea partenerilor, priviri timide și curioase din partea tinerilor și mai ales, ce ne-am dorit dar ce ne-a speriat &icirc;n același timp cel mai mult &ndash; &icirc;ntrebări, chiar multe. Te-ai g&acirc;ndit vreodată că un t&acirc;năr de 12 ani te poate pune &icirc;n dificultate cu o foarte banală &icirc;ntrebare, at&acirc;t de banală &icirc;nc&acirc;t nu ai luat-o niciodată &icirc;n considerare? Cufundați poate prea mult &icirc;n realitatea materială a etnografiei, am scăpat din vedere pentru c&acirc;teva momente partea imaterială, povestea costumului popular, ce poartă cu el și ce ne transmite. Dar știi ce e și mai frumos c&acirc;nd te implici &icirc;ntr-un astfel de proiect bazat pe &icirc;nvățare non-formală? Că &icirc;nvățarea nu este &icirc;ntr-un sens unidirecțional, nu este rigidă, ci dimpotrivă, spontană: &icirc;nveți de la toți participanții, și mai palpitant de at&acirc;t, descoperi &icirc;mpreună cu ei.</p>
<p>         Concret, am realizat ce ne-am propus? Noi zicem că da. Am organizat cele 20 de sesiuni de informare, ateliere de cusut, sesiuni de fotografiere și de feedback de care &icirc;ți povesteam &icirc;n articolul anterior. Au participat mai mult de 100 de beneficiari direcți, tineri ai școlilor gimnaziale partenere, cadre didactice, părinți și frați, membri ai comunităților. Am fotografiat 98 de costume populare, cu mult peste cele 40 la care ne așteptam noi la &icirc;nceputul proiectului. Cel mai important, credem noi, este informația care a rămas &icirc;n urmă, sentimentul că portul popular merită și trebuie protejat și promovat: de aceste aspecte ne-am dat seama din feedback-ul primit, at&acirc;t scris, anonim, c&acirc;t și verbal sau spontan din timpul activităților. Dintr-o statistică simplă, aproximativ 84% dintre beneficiarii direcți au fost &icirc;nc&acirc;ntați de activitățile propuse și consideră că au reținut informații utile despre costumul popular și tehnicile de bază de cusut. Ce este dincolo de aceste cifre, am &icirc;ncercat să rezumăm la &icirc;nceputul articolului.</p>
<p>         Pentru noi proiectul nu a &icirc;nsemnat doar organizarea unor ateliere pur tehnice, ci mai degrabă &icirc;nt&acirc;lniri cu oameni frumoși unde am povestit despre gospodine, bunici, cusături și vremuri &icirc;ndepărtate, de care astăzi ne mai leagă c&acirc;teva amintiri și costumele populare păstrate cu grijă și respect &icirc;n lada de zestre.</p>
<p>			Proiectul &bdquo;Lada de Zestre a Topologului&rdquo; se &icirc;ncheie, așadar, mulțumind tuturor celor care au avut &icirc;ncredere &icirc;n noi, echipei Start ONG și partenerilor, voluntarilor și oamenilor care ne-au primit &icirc;n comunitățile lor cu drag și cu lăzile de zestre deschise.</p>
<p></p>
<p>			Proiectul &rdquo;Lada de Zestre a Topologului&rdquo; este finanțat prin programul Start ONG, lansat de Kaufland Rom&acirc;nia și implementat de Asociația Act for Tomorrow. Domeniile vizate sunt: educație, sănătate, mediu, social și cultură. Valoarea totală a programului este de 500.000 euro. Programul Start ONG este cel mai simplificat instrument de finanțare comunitară destinate ONG-urilor tinere, instituțiilor non-administrative și grupurilor informale/de inițiativă.</p>

        </div>
        </>
    )
}