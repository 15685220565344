import './postStyle.css'
import imagine from "../../../pages/postari/4.cadou/cadou-1.png";
import { Link } from 'react-router-dom';

export default function CadouPost(){
    return(
        <div className="post">
            <img className="postImg" 
            src = {imagine}
            alt=""/>

        <div className="postInfo">
            <div className="postCats">
                <span className="postCat">Proiecte locale</span>
            </div>
            <span className="postTitle">
                <Link className="link" to = "/post/4">Cadoul de Craciun - final de proiect</Link>
                </span>
            <hr/>
            <span className="postDate">23 Decembrie 2022</span>
        </div>
        <p className="postDescription">
        Cadoul de Craciun
        </p>
        </div>
    )
}