import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import "./despre_noi.css";
import teo from './teo.JPG';
import sebi from './sebi.jpg';
import tata from './tata.jpeg';
import mama from './mama.jpg';
import family from './family.jpeg';
import Sidebar from "../../components/sidebar/sidebar";

export default function DespreNoi(){
    return(
        <>
            <Header/>
            
            <div className="despreNoi">
                <div>
                <h1>Despre noi</h1>
            <div className ="half">
                <span className="member">
                    <img className="profile" src = {teo} alt = ""/>
                    <h2 className= "name">Teodora Cantu</h2>
                    <h3>Președinte</h3>
                    <p className="description">Studiez Informatică Economică și doresc să aduc o schimbare în comunitatea din care fac parte. Cred în oameni și în puterea lor de a face lucruri mărețe. 🙂</p>
                </span>

                <span className="member">
                    <img className="profile" src = {sebi} alt = ""/>
                    <h2 className= "name">Sebastian Cantu</h2>
                    <h3>Inginer auto & Vice-președinte</h3>
                    <p className="description">Încă din liceu mi-am dorit să fiu activ și implicat în comunitate. Cred în echilibru și în schimbarea pe care o poate aduce. Sunt pasionat de automobilism, muzică și dans sportiv. 🚗</p>

                </span>


            </div>

            <div className="famDiv">
                <img  className="famImg" src={family} alt=""/>
            </div>

            <div className = "half">
            <span className="member">
                    <img className="profile" src = {tata} alt = ""/>
                    <h2 className= "name">Ion Cantu</h2>
                    <h3>Economist, omul care pune în practică ideile crețe 😉 din organizație</h3>
                    <p className="description">Cred că schimbarea vine în primul rând de la noi și că orice idee oricât de imposibilă poate părea la început, are șanse să fie pusă în practică în pași micuți și siguri.</p>
                </span>

                <span className="member">
                    <img className="profile" src = {mama} alt = ""/>
                    <h2 className= "name">Daniela Cantu</h2>
                    <h3>Cadru didactic, omul cu idei îndrăznețe</h3>
                    <p className="description">Iubesc să lucrez alături de tineri. Îmi doresc să ajut la dezvoltarea lor prin educație. Îmi place să susțin ideile creative și oamenii cu inițiative. Think out of the box! 😉 </p>
                </span>
            </div>

            </div>

            <Sidebar/>
            
            </div>
            <Footer/>
        </>
    )
}