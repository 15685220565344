import './postStyle.css'
import imagine from '../../../pages/postari/1.elisabeta/img2.jpg';
import {Link} from "react-router-dom";
export default function ElisabetaPost(){
    return (

        <div className="post">
            <img className="postImg" 
            src = {imagine}
            alt=""/>

        <div className="postInfo">
            <div className="postCats">
                <span className="postCat">Proiecte locale</span>
            </div>
            <span className="postTitle">
                <Link className="link" to = "/post/1">M.S. Regina Elisabeta și portul popular</Link>
                </span>
            <hr/>
            <span className="postDate">20 August 2020</span>
        </div>
        <p className="postDescription">
        M.S. Regina Elisabeta a iubit portul popular românesc și a fost o adevărată ambasadoare a acestuia. Acțiunile de promovare au început prin a convinge doamnele din înalta societate să poarte costumul tradițional. A susținut astfel eliminarea barierei de gândire potrivit căreia portul tradițional este purtat doar de țărani și nu se cuvine a îi oferi un loc de cinste în garderobele sofisticate ale nobilimii. Regina Elisabeta a dărâmat cu tărie și convingere aceste prejudecăți și prin exemplul personal a încurajat purtarea hainelor tradiționale atât în viața de zi cu zi, cât și la adunări importante și evenimente mondene.
        </p>
        </div>

    )
}