import React from 'react';
import "./topbar.css";
import mainLogo from './logo_pf.png';
import {Link} from "react-router-dom";
export default function TopBar() {
    return (
        <div className = "top">
            <div className = "topLeft">

            <a href = "https://www.facebook.com/Asocia%C8%9Bia-Poarta-F%C4%83g%C4%83ra%C8%99ului-266322627627871/"
            target="_blank">
                <i className="topIcon fab fa-facebook"></i>
                </a>
            <a target="_blank" href="https://www.instagram.com/poartafagarasului/">
                <i className ="topIcon fab fa-instagram">
                </i>
            </a>
            </div>
            <div className = "topCenter">
                <ul className="topList">
                    <li className="topListItem">
                        <Link className="link" to="/">Acasa</Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/despre-noi">Despre noi</Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to = "/activitati">Activitati</Link>
                        </li>
                    <li className="topListItem">
                        <Link className="link" to ="/contact">Contact</Link>
                        </li>
                </ul>
            </div>
            <div className = "topRight">
                <img 
                className = "topImg"
                src={mainLogo}
                alt=""/>
            </div>
        </div>
    )
}