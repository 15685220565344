import "./header.css"
import headerImg from "./header.jpg"

export default function Header() {
    return (
        <div className = "header">
            <div className="headerTitles">
                <span className="headerTitleLg">Asociația Poarta Făgărașului</span>
            </div>
            <img className="headerImg"
            src = {headerImg}
            alt = ""
            />
        </div>
    )

}