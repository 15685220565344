import './postStyle.css'
import imagine from '../../../pages/postari/2.lzt/img1.png';
import { Link } from 'react-router-dom';


export default function LztPost(){
    return (
        <div className="post">
            <img className="postImg" 
            src = {imagine}
            alt=""/>

        <div className="postInfo">
            <div className="postCats">
                <span className="postCat">Proiecte locale</span>
            </div>
            <span className="postTitle">
                <Link className="link" to = "/post/2">Lada de Zestre a Topologului</Link>
                </span>
            <hr/>
            <span className="postDate">2 Iulie 2021</span>
        </div>
        <p className="postDescription">
        Când te gândești la costumul popular, tu ce imagine ai în minte? Sau, oare, să te întreb altfel: ai o singură imagine, sau nu te poți hotărî ce să alegi dintre 15-20 de imagini?
        </p>
        </div>
    )
}